import React from 'react';
import ReactDOM from 'react-dom/client';
import ChartPage from './ChartPage';
import './db';

import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime2/lib/css/blueprint-datetime2.css';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";

const root = ReactDOM.createRoot(document.getElementById('mount'));
root.render(<ChartPage />);
