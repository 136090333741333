import React, {useEffect, useMemo, useState} from 'react';
import {DateRangeInput3} from '@blueprintjs/datetime2';
import {
  Colors, Navbar, Button, FormGroup, HTMLSelect, Alignment, Intent, Tooltip,
  Callout, Icon, Callout,
} from '@blueprintjs/core';
import {DateTime} from 'luxon';

import ChartRenderer from './ChartRenderer';
import {getWorker} from './db';

const styles = {
  bar: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '10px 10px 0px 10px',
    background: Colors.LIGHT_GRAY5,
    borderTop: `1px solid ${Colors.LIGHT_GRAY1}`,
    borderBottom: `1px solid ${Colors.LIGHT_GRAY1}`,
  },

  group: {
    marginRight: 10,
  },

  spacer: {
    flex: 1,
  },

  buttons: {
    alignSelf: 'center',
  },

  checkboxContainer: {
    marginBottom: 15,
  },

  footer: {
    padding: '15px',
  },
};

const ChartPage = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [maxDate, setMaxDate] = useState(() => DateTime.now());
  const [data, setData] = useState([]);
  const [borough, setBorough] = useState('systemwide');
  const [resolution, setResolution] = useState('h');
  const now = useMemo(() => DateTime.now());
  const minDate = useMemo(() => DateTime.fromObject({year: 2013, month: 6, day: 1}));

  useEffect(() => {
    (async () => {
      const worker = await getWorker();
      const q = "SELECT MAX(datetime) AS max FROM hourly_volume_rollup"
      const [{max}] = await worker.db.query(q);

      const maxRollupDate = DateTime.fromSeconds(max).minus({days: 1});

      // TODO: Properly account for UTC -> NYC timezone shift. This is obv jank.
      setMaxDate(maxRollupDate);
      setDateRange([
        maxRollupDate.minus({days: 90}),
        maxRollupDate,
      ]);
    })();
  }, []);

  useEffect(() => {
    if (!dateRange[0] || !dateRange[1]) return;

    (async () => {
      const worker = await getWorker();
      const datetime = (() => {
        if (resolution === 'h') {
          return 'datetime';
        }
        if (resolution === 'd') {
          return "date(local_date)";
        }

        return "date(local_date, 'start of month')";
      })();

      
      const startDate = dateRange[0]
        .startOf('day')
        .setZone("America/New_York", { keepLocalTime: true })
        .setZone("UTC");
      const endDate = dateRange[1]
        .endOf('day')
        .setZone("America/New_York", { keepLocalTime: true })
        .setZone("UTC");

      const resp = await worker.db.query(`
        SELECT
          ${datetime} AS datetime,
          SUM(manhattan_trips) + SUM(brooklyn_trips) + SUM(bronx_trips) + SUM(queens_trips) + SUM(staten_island_trips) AS total_trips,
          SUM(manhattan_trips) AS manhattan_trips,
          SUM(brooklyn_trips) AS brooklyn_trips,
          SUM(queens_trips) AS queens_trips,
          SUM(bronx_trips) AS bronx_trips,
          SUM(staten_island_trips) AS staten_island_trips
        FROM hourly_volume_rollup
        WHERE
          datetime >= ? AND
          datetime < ?
        GROUP BY
          ${datetime}
      `, [startDate.toUnixInteger(), endDate.toUnixInteger()]);

      setData(resp);
    })();
  }, [dateRange, resolution]);

  const onChangeDate = ([start, end]) => {
    setDateRange([
      DateTime.fromJSDate(start),
      DateTime.fromJSDate(end),
    ]);
  };

  return (
    <>
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading><Icon icon="cycle" /> Citibike Explorer</Navbar.Heading>
          <Navbar.Divider />
          <Button
            minimal
            intent={Intent.PRIMARY}
            icon="chart"
            text="Trip volume"
          />
          <Tooltip content="Coming eventually, maybe!">
            <Button minimal disabled icon="flag" text="Station volume" />
          </Tooltip>
        </Navbar.Group>
      </Navbar>
      <div style={styles.calloutContainer}>
        <Callout title="Welcome!" icon={false} intent={Intent.PRIMARY}>
            You're looking at a chart of how many rides have been taken on New York's Citibike bike sharing system over time. Everything you see here is derived from the publicly available <a href="https://citibikenyc.com/system-data" target="_blank">trip history</a> dataset, updated once a month as new data is released.
        </Callout>
      </div>
      <div style={styles.bar}>
        <FormGroup style={styles.group} label="Date range">
          <DateRangeInput3
            disabled={!dateRange[0]}
            value={dateRange.map((d) => d && d.toJSDate())}
            onChange={onChangeDate}
            minDate={minDate.toJSDate()}
            maxDate={maxDate.toJSDate()}
            shortcuts={false}
          />
        </FormGroup>
        <FormGroup label="Resolution" style={styles.group}>
          <HTMLSelect value={resolution} onChange={(e) => setResolution(e.target.value)}>
            <option value="h">Hourly</option>
            <option value="d">Daily</option>
            <option value="m">Monthly</option>
          </HTMLSelect>
        </FormGroup>
        <FormGroup style={styles.group} label="Borough">
          <HTMLSelect value={borough} onChange={(e) => setBorough(e.target.value)}>
            <optgroup label="Breakdowns">
              <option value="systemwide">System-wide</option>
              <option value="separated">Separated</option>
            </optgroup>
            <optgroup label="Individual">
              <option value="bronx">Bronx</option>
              <option value="brooklyn">Brooklyn</option>
              <option value="manhattan">Manhattan</option>
              <option value="queens">Queens</option>
              <option value="staten_island" disabled>Staten Island</option>
            </optgroup>
          </HTMLSelect>
        </FormGroup>
        <div style={styles.spacer} />
      </div>
      <ChartRenderer borough={borough} data={data} resolution={resolution} />
      <div style={styles.footer}>
        Most recent available trip data is from <b>{maxDate.toFormat('LLLL yyyy')}</b>.<br /><br />
        Citibike Explorer is &copy;{now.year} <a href="https://stevegattuso.me">Steve Gattuso</a> and released under the <a href="https://git.sr.ht/~vesto/citibike.stevegattuso.me/tree/main/item/LICENSE.txt">GPL v3 license</a>.<br />
        All source code is available on <a href="https://git.sr.ht/~vesto/citibike.stevegattuso.me">SourceHut</a>.
      </div>
    </>
  );
};

export default ChartPage;
