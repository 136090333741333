import React, {useMemo, useRef, useEffect} from 'react';
import {Colors} from '@blueprintjs/core';
import {DateTime} from 'luxon';
import * as d3 from 'd3';

const styles = {
  chart: {
    width: '100%',
    height: 400,
  },
};

const margin = {
  top: 15,
  left: 50,
  bottom: 25,
  right: 15,
};

const colors = {
  total: Colors.BLUE1,
  manhattan: Colors.RED1,
  brooklyn: Colors.GOLD1,
  queens: Colors.GREEN1,
  bronx: Colors.CERULEAN1,
  staten_island: Colors.LIME1,
};

const ChartRenderer = ({data, resolution, borough}) => {
  const svgRef = useRef();
  const yAxisRef = useRef();
  const xAxisRef = useRef();

  const parseDatetime = (dt) => {
    if (Number.isInteger(dt)) {
      return DateTime.fromSeconds(dt);
    }

    return DateTime.fromFormat(dt, 'yyyy-MM-dd');
  };

  const boros = [
    'total', 'manhattan', 'brooklyn', 'queens', 'bronx', 'staten_island'
  ].filter((b) => {
    if (borough === 'systemwide') {
      return b === 'total';
    }
    if (borough === 'separated') {
      return b !== 'total';
    }

    return b === borough;
  });

  const chart = useMemo(() => {
    if (!svgRef.current) return;

    const transformed = data.map((d) => ({
      ...d,
      datetime: parseDatetime(d.datetime),
      visableTotal: boros.reduce((sum, boro) => sum + (d[`${boro}_trips`] || 0), 0),
    }));
    const [, maxY] = d3.extent(transformed.map((d) => d.visableTotal));
    const [minTs, maxTs] = d3.extent(transformed.map((d) => d.datetime.toJSDate()));

    const bounds = svgRef.current.getBoundingClientRect();
    const x = d3.scaleTime()
      .domain([minTs, maxTs])
      .range([0, bounds.width - (margin.left + margin.right)]);
    const y = d3.scaleLinear()
      .domain([0, maxY * 1.1])
      .range([bounds.height - (margin.top + margin.bottom), 0]);

    const lines = boros.map((boro) => {
      return [boro, d3.line()
        .curve(d3.curveNatural)
        .x((d) => x(parseDatetime(d.datetime).toJSDate()))
        .y((d) => y(d[`${boro}_trips`]))];
    });

    return {x, y, bounds, lines};
  }, [data, resolution, svgRef, borough]);

  useEffect(() => {
    if (!chart) return;
    const bounds = svgRef.current.getBoundingClientRect();

    const yAxis = d3.axisLeft(chart.y);
    d3.select(yAxisRef.current)
      .call(yAxis)
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    const xAxis = d3.axisBottom(chart.x);
    d3.select(xAxisRef.current)
      .call(xAxis)
      .attr('transform', `translate(${margin.left}, ${bounds.height - margin.bottom})`);
  }, [borough, resolution, chart?.y, chart?.x, xAxisRef, yAxisRef]);

  return (
    <svg ref={svgRef} style={styles.chart}>
      <g ref={yAxisRef} />
      <g ref={xAxisRef} />
      <g transform={`translate(${margin.left}, ${margin.top})`}>
        {chart?.lines && chart.lines.map(([boro, line]) => (
          <path
            d={line(data)}
            stroke={colors[boro]}
            stroke-width={1.5}
            fill="transparent"
          />
        ))}
      </g>
    </svg>
  );
};

export default ChartRenderer;
