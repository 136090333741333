import {createDbWorker} from "sql.js-httpvfs";
import database from 'url:../data/data.sqlite3';

const wasmUrl = new URL(
  '../../node_modules/sql.js-httpvfs/dist/sql-wasm.wasm',
  import.meta.url
);
const workerUrl = new URL(
  "../../node_modules/sql.js-httpvfs/dist/sqlite.worker.js",
  import.meta.url
);

const worker = (async () => {
  return await createDbWorker(
    [
      {
        from: "inline",
        config: {
          serverMode: "full",
          url: database,
          requestChunkSize: 4096,
        },
      },
    ],
    workerUrl.toString(),
    wasmUrl.toString()
  );
})();

export const getWorker = async () => await worker;
